<template>
    <div>
        <nav-index :title="Object.keys(this.prompt).length > 0 ? this.prompt.text : '生成文本'"/>
        <div class="output-box" ref="outputBox">
            <div class="img">
              <img :src="prompt.img" alt="" width="90" height="90">
            </div>
            <div class="desc">
              {{  prompt.desc  }}
            </div>
            <div class="example">
              <div class="button-sm color-yellow" @click=handleClick(0)>范例1</div>
              <div class="button-sm color-bule" @click=handleClick(1)>范例2</div>
              <div class="button-sm color-green" @click=handleClick(2)>范例3</div>
            </div>
            <div class="row">
              <textarea ref="textarea" wrap="soft" placeholder="输入您的描述..." class="textarea" v-model="promptText"
              @input="adjustTextareaHeight" maxlength="1000" rows="5"></textarea>
            </div>
            <div class="button-row">
              <div class="button-lg" @click="generateResult">
                <span v-show="!isLoading && printedText.length === 0">生成</span>
                <span v-show="!isLoading && printedText.length > 0">重新生成</span>
                <span v-show="isLoading">努力生成中</span>
                <van-loading color="white" size="24px" v-show="isLoading"/>
              </div>
            </div>
            <div class="chat-message-text" v-show="printedText.length > 0">
                <div ref="output">
                  <div class="output-html" >
                    <div class="text-container">
                      <span>{{ printedText }}</span>
                      <span ref="cursor" class="cursor" :class="{ visible: cursorVisible }" v-show="cursorVisible"></span>
                    </div>
                  </div>
                </div>
            </div>
            <div class="button-row" v-show="showCopyButton">
              <div class="button-lg blue" @click="copyToClipboard">
                <span>复制</span>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavIndex from '../../components/nav/index.vue'
import { allPrompt } from '../../assets/js/allPrompt'
import promptExamples from '../../assets/json/promptExamples.json'
import { sparkAssitantChat } from '@/api/api'
import {Toast} from 'vant'
import { wxSdkShare } from '@/utils/index';
const seo = require('../../../config/index')

export default {
  name: 'Output',
  components: {
    NavIndex,
  },
  created(){
    this.initData()
  },
  mounted(){
    this.wxShareInit()
  },
  data(){
      return {
        prompt:{},
        promptText:'',
        resultText:'',
        isLoading: false,
        printedText: '',  // 用于存储逐个打印的文字
        cursorVisible: false,  // 控制光标的可见性
        isLogin: localStorage.getItem('token') ? true : false,
        showCopyButton: false,
      }
  },
  methods:{
    adjustTextareaHeight(){
      this.$refs.textarea.style.height = 'auto';
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 5 + 'px';
    },
    initData(){
      let tempArr = allPrompt.filter(item=>{
        return item.id === this.$route.query.id
      })
      this.prompt = tempArr[0]
    },
    handleClick(i){
      this.promptText = promptExamples[this.prompt.id][i].prompt
      this.resetValue()
    },
    resetValue(){
      this.resultText = ''
      this.printedText = ''
      this.showCopyButton = false
    },
    wxShareInit(){
      let params = {}
      params.url = seo.config.wxConfig.outputPage.link + this.$route.fullPath
      params.context = seo.config.wxConfig.outputPage.desc
      params.title = this.prompt.text + '工具' // 组件内配置工具名称
      params.imgUrl = seo.config.wxConfig.outputPage.imgUrl
      params.appId = seo.config.wxConfig.appId
      wxSdkShare(params)
    },
    async generateResult(){
      if(!this.isLoading){
        this.isLoading = true
        this.cursorVisible = false // 光标默认不显示
        this.resetValue()
        if (this.promptText.trim() === "") return;
        let uid = this.isLogin ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
        // 滚动到底部
        let params = this.promptText
        this.$nextTick(()=>{
          this.adjustTextareaHeight()
          let outputBox = this.$refs.outputBox;
          outputBox.scrollTop = outputBox.scrollHeight;
        })
        // 远程请求结果
        let res = await sparkAssitantChat({ text: params, uid: uid, promptOption: true, id: this.prompt.id })
        if(res.data.message === 'success'){
          // 监管
          // Toast('抱歉，服务器繁忙中...');
          // 远程保存聊天记录
          // this.isLogin && addchat({ sender: uid, receiver: 'bot', message: params })
          this.resultText = res.data.data
          this.$nextTick(() => {
            // 远程保存机器人回答
            setTimeout(()=>{  // 保证先保存提问，再保存回答
              // this.isLogin && addchat({ sender: 'bot', receiver: uid, message: content })
            },0)
            // 流式打印效果
            this.printMsg()
          });
        } else {
          Toast('会员积分不足');
        }
      }
    },
    printMsg(type){
      const lastOutputEl = this.$refs.output
      const cursorEl = this.$refs.cursor;
      if (lastOutputEl && cursorEl) {
        let index = 0
        this.printedText = ''
        const outputIntervalId = setInterval(() => {
          const outputChar = this.resultText.charAt(index)
          this.printedText += outputChar
          index++
          if (index > this.resultText.length) {
            clearInterval(outputIntervalId)
            if(type !== 'waiting'){
              this.cursorVisible = false; // 停止闪烁，隐藏光标
              this.showCopyButton = true // 显示复制按钮
              this.isLoading = false
            }
          }
          this.$nextTick(() => {
            let outputBox = this.$refs.outputBox;
            outputBox.scrollTop = outputBox.scrollHeight;
          })
        }, 50) // 每150毫秒输出一个字符
      }
    },
    // 复制到剪切板
    copyToClipboard() {
      const textarea = document.createElement("textarea"); // 创建一个textarea元素
      textarea.value = this.resultText; // 设置textarea的值
      document.body.appendChild(textarea); // 将textarea添加到DOM中
      textarea.select(); // 选中textarea中的文本
      document.execCommand("copy"); // 将选中的文本复制到剪贴板中
      document.body.removeChild(textarea); // 从DOM中移除textarea
      Toast('已复制');
      this.showCopyButton = false
    },
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.output-box {
  margin-top: 55px;
  height: calc(100vh - 55px);
  overflow-y: scroll;
  .img {
    padding: 15px 0;
  }
  .desc {
    margin: 10px 0;
    font-size: 14px;
  }
  .example {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 15px;
    .button-sm {
      margin: 0 5px;
      width: 33%;
      padding: 5px;
      font-size: 14px;
      border-radius: 5px;
    }
    .color-yellow {
      border: 1px solid #f5dab1;
      background: #fdf6ec;
      color: #e6a23c;
    }
    .color-bule {
      border: 1px solid #b3d8ff;
      background: #ecf5ff;
      color: #409eff;
    }
    .color-green {
      background: #f0f9eb;
      border: 1px solid #c2e7b0;
      color: #67c23a;
    }
  }
  .row {
    margin: 0 15px;
    .textarea{
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        font-family: PingFangSC-Semibold, PingFang SC;
        line-height: 20px;
        outline: 0;
        word-wrap: break-word;
        overflow-x: hidden;
        overflow-y: auto;
        background: #fefefe;
        border-radius: 10px;
    }
    textarea{
      border-radius: 15px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      padding: 10px;
      outline:none;
      resize:none;
    }
  }
  .button-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    .button-lg {
      width: 75%;
      height: 40px;
      display: flex;
      line-height: 40px;
      border-radius: 20px;
      align-items: center;
      font-size: 18px;
      color: #fff;
      background: #ec745b;
      justify-content: center;
      border: 1px solid #ec745b;
      box-shadow: 1px 1px 4px #ec745b;
    }
    .blue {
      background: #5bcaec;
      border: 1px solid #5bcaec;
      box-shadow: 1px 1px 4px #5bcaec;
    }
  }
  .chat-message-text {
      background-color: #fff;
      margin: 15px;
      text-align: left;
      border-radius: 10px;
      padding: 10px 15px;
      white-space: pre-wrap;
      p {
        margin: 1px;
      }
  }
  .output-html {
    position: relative;
  }

  .text-container {
    display: inline-block;
    position: relative;
  }
  .cursor {
    display: inline-block;
    width: 6px; /* 调整光标宽度 */
    height: 17px;
    line-height: 17px;
    background-color: #000;
    animation: blink-animation 0.9s infinite;
    margin-left: 5px; /* 添加光标与文字之间的间隔 */
    margin-top: 0px;
    vertical-align: middle; /* 光标垂直居中对齐 */
  }
}
.van-loading {
    position: relative !important;
    left: 5px;
    top: 0;
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    font-size: 0;
    vertical-align: middle;
    -ms-transform: none;
}
</style>
